// extracted by mini-css-extract-plugin
export var VerticalTimeline = "Features-module--VerticalTimeline--f76ab";
export var description = "Features-module--description--9608e";
export var effort = "Features-module--effort--25a42";
export var featuresDecR = "Features-module--featuresDecR--9bd24";
export var head = "Features-module--head--83e35";
export var heading = "Features-module--heading--2185c";
export var mainDes = "Features-module--mainDes--82713";
export var onDemandTimeLine = "Features-module--onDemandTimeLine--b243b";
export var subTitle = "Features-module--subTitle--0fe9e";
export var verticalElement = "Features-module--verticalElement--180e6";