import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Results.module.scss"

const Results = ({ strapiData }) => {
  return (
    <div className={styles.Ideation}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className={styles.dec}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div className={styles.cardsData}>
          <div className={styles.leftText}>
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i < 2 && (
                    <div className={styles.cards} key={i}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                  )
              )}
          </div>
          <div className={styles.closeUp}>
            <StaticImage
              decoding="async"
              loading="lazy"
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Group_100s0007072_1bfc5d8ae2.webp"
              alt="Result"
            />
          </div>
          <div className={styles.rightText}>
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i > 1 && (
                    <div className={styles.cards2} key={i}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                  )
              )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Results
