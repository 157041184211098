// extracted by mini-css-extract-plugin
export var Challenges = "Approach-module--Challenges--b8a4e";
export var cards = "Approach-module--cards--72610";
export var description = "Approach-module--description--b3b33";
export var hire = "Approach-module--hire--1eee6";
export var hireHeading = "Approach-module--hireHeading--2d86f";
export var moduleColumnPlay = "Approach-module--moduleColumnPlay--783d5";
export var productHeading = "Approach-module--productHeading--8afc8";
export var talent = "Approach-module--talent--dad11";
export var text = "Approach-module--text--5d51a";
export var tick = "Approach-module--tick--04333";