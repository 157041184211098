import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import SEORevamp from "../../components/common/SEO_Revamp"
import MainLayout from "../../layouts/MainLayout"
import Banner from "../../components/nymcard/Banner"
import Challenges from "../../components/nymcard/Challenges"
import Solutions from "../../components/nymcard/Solutions"
import FeatureMobile from "../../components/globalreader/FeatureMobile"
import Approach from "../../components/column/Approach"
import Steps from "../../components/column/Steps"
import Platform from "../../components/column/Platform"
import Features from "../../components/globalreader/Features"
import Results from "../../components/column/Results"
import ResultsMobile from "../../components/column/ResultsMobile"
import Cta from "../../components/nymcard/Cta"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        image="https://invozone-backend.s3.amazonaws.com/Portfolio_059cb9635f.png"
      />
    </>
  )
}
const nymcard = ({ data }) => {
  // console.log(data)
  const heroSection = data?.strapiPage?.sections[0]
  const challenges = data?.strapiPage?.sections[1]
  const solutions = data?.strapiPage?.sections[2]
  const objectives = data?.strapiPage?.sections[3]
  const approach = data?.strapiPage?.sections[4]
  const steps = data?.strapiPage?.sections[5]
  const feature = data?.strapiPage?.sections[6]
  const modules = data?.strapiPage?.sections[7]
  const results = data?.strapiPage?.sections[8]
  const plateform = data?.strapiPage?.sections[9]
  const cta = data?.strapiPage?.sections[10]

  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 1280) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 1280)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return (
    <MainLayout bgChanged={false}>
      <Banner strapiData={heroSection} column={true} />
      <Challenges strapiData={challenges} />
      <Solutions
        strapiData={solutions}
        nymcardSolution={true}
        columnSolution={true}
      />
      <Challenges strapiData={objectives} />
      <Approach strapiData={approach} />
      <Steps strapiData={steps} />
      {!isMobile && !isTablet ? (
        <Features strapiData={feature} featuresDec={true} />
      ) : (
        <FeatureMobile strapiData={feature} />
      )}
      <Approach strapiData={modules} moduleColumn={true} />
      {/* <Steps strapiData={results} /> */}
      {!isMobile ? (
        <Results strapiData={results} />
      ) : (
        <ResultsMobile strapiData={results} />
      )}
      <Platform strapiData={plateform} />
      <Cta strapiData={cta} ctaColumn={true} />
      <ContactSales bgColor="#F8F8F8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "column-1" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default nymcard
