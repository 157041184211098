import React from "react"
import Container from "react-bootstrap/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from "./Steps.module.scss"

const Challenges = ({ strapiData, product }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={`${styles.Challenges} `}>
      <Container>
        <div className={styles.hire}>
          <h2
            className={`${styles.hireHeading} `}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div className={` text-center ${styles.stepsImg} `}>
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.title}
              />
            ) : (
              <img
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={strapiData?.secImages[0]?.alternativeText}
                decoding="async"
                loading="lazy"
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Challenges
