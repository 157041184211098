import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Approach.module.scss"

const Challenges = ({ strapiData, product, moduleColumn }) => {
  // const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={`${styles.Challenges} `}>
      <Container>
        <div className={styles.hire}>
          <h2
            className={`${styles.hireHeading} ${
              product ? styles.productHeading : ""
            }`}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <div className={styles.cards}>
            {strapiData?.cards &&
              strapiData?.cards?.map((item, index) => (
                <div className={styles.talent} key={index}>
                  <div className={styles.text}>
                    <img
                      className={`${styles.tick} ${
                        moduleColumn ? styles.moduleColumnPlay : ""
                      }`}
                      src="https://invozone-backend.s3.us-east-1.amazonaws.com/Polygon_1_7938a5beae.svg"
                      alt={"icon"}
                      decoding="async"
                      loading="lazy"
                      style={{
                        height: "16px",
                        width: "16px",
                        marginRight: "38px",
                      }}
                    />
                    <p>{item?.subTitle}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Challenges
