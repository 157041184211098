import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./ResultsMobile.module.scss"

const cardData = [
  {
    image:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/framework_f79cc4f70b.png",
    dec: `Built a technological framework. Our dedicated team created an infrastructure to support distributed application development and execution.`,
  },
  {
    image:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/api_89a5b08662.png",
    dec: `The company now has 150+ growing APIs and has launched solutions such as Prepaid cards, Multi-currency, Charge and Credit cards, and Buy Now, Pay Later.`,
  },
  {
    image:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/growth_2b93796011.png",
    dec: `Focused on growth and innovation while we handled the heavy lifting.`,
  },
  {
    image:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/funding_e16c8bfae7.png",
    dec: `Secured Funding $ 7.6 M. The company scored funding of $7.6 million in a Series A round in 2021.`,
  },
  {
    image:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/zwipe_eaf3c9a3a7.png",
    dec: `Collaborate with Zwipe. A successful collaboration with Zwipe, a Norwegian biometric technology company, will launch biometric cards in Africa.`,
  },
  {
    image:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/mastercard_be710cb9e5.png",
    dec: `Mastercard certifies fintech, NymCard, as a principal issuer.`,
  },
  {
    image:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/western_union_5e24abe458.png",
    dec: `Western Union partners with NymCard in the UAE`,
  },
  {
    image:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/live_program_f8320b7048.png",
    dec: `Live programs in 3 global regions.`,
  },
  {
    image:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/partnership_ce1118d7ae.png",
    dec: `Multiple International Partnerships`,
  },
  {
    image:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/global_91b60ebf16.png",
    dec: `Staff in 10+ Countries`,
  },
]

const Results = ({ strapiData }) => {
  return (
    <div className={`${styles.result}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`${styles.cardWrapper} gap-30 d-flex`}>
          {strapiData?.cards &&
            strapiData?.cards?.map((e, i) => (
              <Col xl={4} md={6} xs={12} key={i} className=" gap-30 d-flex">
                <div className={styles.card}>
                  <img
                    src={e?.image1[0]?.localFile?.publicURL}
                    alt={strapiData?.title}
                    decoding="async"
                    loading="lazy"
                  />
                  <p>{e?.subTitle}</p>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  )
}

export default Results
