import React, { useState, useEffect } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import * as styles from "./Features.module.scss"
import "./feature.scss"

const Features = ({ strapiData, featuresDec }) => {
  const [activeCard, setActiveCard] = useState(0)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [activeCard])

  const handleScroll = () => {
    const timelineElements = document.querySelectorAll(
      ".vertical-timeline-element--work"
    )

    timelineElements.forEach((element, index) => {
      const rect = element.getBoundingClientRect()

      if (
        rect.top <= window.innerHeight / 2 &&
        rect.bottom >= window.innerHeight / 2
      ) {
        setActiveCard(index)
      }
    })
  }

  const renderIconStyle = i => {
    const isActive = i === activeCard
    const borderColor = isActive ? "#2176FF" : "black"
    let marginTop = "110px"

    if (i === strapiData.cards.length - 1) {
      marginTop = "130px"
    }

    return {
      // background: "#2176FF",
      border: `10px solid ${borderColor}`,
      borderRadius: "50%",
      width: "20px",
      height: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "-10px",
      marginTop: marginTop,
    }
  }

  const renderIcon = i => {
    const isActive = i === activeCard
    const iconStyle = {
      color: isActive ? "#2176FF" : "black",
    }

    return <div style={iconStyle} />
  }

  return (
    <div className={`${styles.onDemandTimeLine} Product`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <Row className={`mt-5`}>
          <VerticalTimeline className={`${styles.VerticalTimeline} feature`}>
            {strapiData?.cards?.map((e, i) => (
              <VerticalTimelineElement
                key={i}
                className={`vertical-timeline-element--work ${styles.verticalElement}`}
                contentArrowStyle={{ borderRight: "0px solid #2176FF" }}
                iconStyle={renderIconStyle(i)}
                icon={renderIcon(i)}
              >
                <div className="text-start">
                  <img
                    src={e?.image1[0]?.localFile?.publicURL}
                    decoding="async"
                    loading="lazy"
                    alt={e?.title}
                    style={{ height: "60px", width: "60px" }}
                  />
                  <h3 className={styles.head}>{e?.title}</h3>
                  <p
                    className={styles.mainDes}
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </Row>
        <div
          className={`${styles.effort} ${
            featuresDec ? styles.featuresDecR : ""
          }`}
        >
          <img
            src="https://invozone-backend.s3.us-east-1.amazonaws.com/sum_up_db2f0d8ec5.png"
            alt="effort"
            decoding="async"
            loading="lazy"
            style={{
              height: "60px",
              width: "60px",
              marginRight: "20px",
            }}
          />
          <p
            className={styles.subTitle}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
      </Container>
    </div>
  )
}

export default Features
